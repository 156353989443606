.NewsPage {
    background-color: $cc-grey;

    .news-block {
        color: $cc-black;


        .news-item {
            &:hover {
                .content {
                    img {
                        filter: none;
                    }
                }
            }

            .content {
                background-color: #ffffff;

                img {
                    -webkit-filter: grayscale(100%);
                    filter: grayscale(100%);
                    transition: filter .4s;
                }

                .content-inner {
                    padding: 25px;
                }

                .title {
                    color: grey;
                    text-decoration: underline;
                }

                h2 {
                    font-size: 1.4rem;
                }

                a {
                    text-align: center;
                    display: block;
                    border-top: 2px solid black;
                    width: 100%;
                    padding: 0px;
                    padding: 25px 0;
                    color: $cc-orange;
                    text-decoration: none;

                    &:hover {
                        background-color: $cc-orange;
                        border: #ffffff;
                        color: #ffffff;
                    }
                }
            }

        }
    }

}


.NewsPage {
    .object-fit-polyfill {
        padding-bottom: calc(100% / 16 * 15);
    }
}

.NewsDetailPage,
.NewsletterPage {
    img {
        width: 100%;
    }

    .news-content {
        background-color: #ffffff;
        padding: 25px;
    }

    .object-fit-polyfill {
        padding-bottom: calc(100% / 16 * 9);
    }

    .header {
        color: #ffffff;
    }

    .Button--primary {
        text-transform: uppercase;
    }

    .links {
        @include mq($from: $viewport--md) {


            display: flex;
            justify-content: space-between;
            align-items: center;
            justify-content: center;
        }

        .back {
            @include mq($from: $viewport--md ) {
                margin-left: 15px;
            }
            color: $cc-black;
            text-decoration: none;
            border-bottom: 1px solid $cc-black;
        }
    }

    .quote {
        color: $cc-orange;

        p {
            font-size: 1.8rem;
            font-weight: 500;

            &::before,
            &::after {
                content: '"'
            }
        }
    }
}
