/**
 * Mixin: Grid
 */

 @mixin grid($cols, $rows) {
    display: grid;
    grid-template-columns: $cols;
    grid-template-rows: $rows;
}

@mixin grid-area ($col-start, $col-end, $row-start, $row-end) {
    grid-column: #{$col-start}/#{$col-end};
    grid-row: #{$row-start}/#{$row-end};
}

@mixin grid-row($row-start, $row-end) {
    -ms-grid-row: $row-start;
    -ms-grid-row-span: $row-end - $row-start;
    grid-row: #{$row-start}/#{$row-end};
}

@mixin grid-columns($col-start, $col-end) {
    -ms-grid-column: $col-start;
    -ms-grid-column-span: $col-end - $col-start;
    grid-column: #{$col-start}/#{$col-end};
}
