/**
 * Footer
 */

.Footer {
    .footer-main {
        position: relative;
        padding: 0 45px;

        span{
            background-color: $cc-orange;
            color: #ffffff;
            transform-origin: 0 100%;
            top: -129px
        }

        &::before {
            content: "";
            background-color: #ffffff;
            height: calc(100% + 210px );
            width: 2px;
            position: absolute;
            left: 0;
            top: -105px;
            
            z-index: 1;
        }
    }

    padding: 105px 0;
    background-color: $cc-orange;
    color: #ffffff;

    .Footer-top {
        @include mq ($from: $viewport--md) {
            display: flex;
            justify-content: space-between !important;
        }

        border-bottom: 8px solid #ffffff;

        p {
            font-size: 1.4rem;
        }
    }

    .Footer-center {
        .Button--secondary {
            min-height: 75px;
            padding: 18px 0;
        }
    }

    .Footer-bottom {
        text-align: center;
        align-items: center;

        a {
            color: #ffffff;
        }
    }

    // .Container {
    //     .vertical-text {
    //         transform: rotate(90deg);
    //         transform-origin: left top;
    //         color: #ffffff;
    //         &::before {
    //             content: "";
    //             height: 2px;
    //             width: 87%;
    //             background-color: #ffffff;
    //             position: absolute;
    //             z-index: 99;
    //             top: 50%;
    //             right: 0;
    //         }
    //     }
    // &::before {
    //     content: "";
    //     top: 0;
    //     bottom: 0;
    //     left: 15px;
    //     width: 2px;
    //     background-color: #ffffff;
    //     position: absolute;
    // }
}
