/**
 * General
 */

 .decription {
     p {
         font-weight: 500;
     }
 }
.HomePage {
    .object-fit-polyfill {
        padding-bottom: calc(100% / 16 * 9);
    }

    .Main {
        @include grid(100%, auto 180px auto);

        .Container {
            &-main {
                margin-top: -180px;

            }

        }
    }
}

.Main {
    @include grid(100%, auto 125px auto);

    .Container {
        &-main {
            margin-top: -125px;
            @include grid-area(1, 2, 3, 4);
        }
    }
}

.anchor-point {
    z-index: 99;
    color: $cc-orange;
    position: absolute;
    background-color: #ffffff;
    top: -40px;
    left: 12px;
    padding: 0 15px;
    transform: rotate(-90deg) translateX(-100%);
    transform-origin: 0 100%;
}

.intro {
    margin-top: 40px;
    margin-bottom: 40px;
    @include grid-area(1, 2, 1, 2);
    color: #ffffff;
    p {
        font-weight: 500;
    }
}

.description {
    color: #ffffff;
    font-size: 1.7rem;
    line-height: 1.3;
}

.aanbod-block {
    margin-top: 55px;
    position: relative;
    background-color: $cc-grey;
    padding: 50px 30px;
    color: $cc-black;
    margin: 25px auto;

    &:hover {
        background-color: $cc-black;

        p,
        a,
        h2 {
            color: #ffffff;
        }
    }

    &:hover,
    p,
    a,
    h2 {
        transition: .7s all ease;
    }

    .counter {
        p {
            color: $cc-orange;
            font-size: 1.3rem;
            margin-bottom: 8px;
            font-weight: 500;
        }
    }

    p {
        margin-bottom: 50px;
    }

    a {
        position: absolute;
        bottom: 30px;
        color: $cc-black;
        font-weight: 500;
    }
}

strong {
    font-weight: 600;
    &.orange {
        font-weight: normal;
        color: $cc-orange;
        font-weight: 500;
    }

}

.HomePage {
    .homepage-block {
        position: relative;

        &::before {
            content: "";
            background-color: $cc-orange;
            height: 100%;
            width: 2px;
            position: absolute;
            left: 0;
            top: 6rem;
            bottom: -24px;
            z-index: 1;
        }

        .Media-object {
            z-index: 2;
        }
    }


    .column {
        position: relative;

        picture {
            @include grid-area(1, 2, 1, 2);

            @include mq ($until: $viewport--md) {
                img {
                    position: relative !important;
                }

            }
        }

        h2 {
            @include grid-area(1, 2, 2, 3);
            padding: 0 50px;
        }

        .text {
            @include grid-area(1, 2, 3, 4);

            @include mq ($until: $viewport--md) {
                width: 100%;
                display: block;
            }

            padding: 0 25px;

            p {
                display: block;
            }
        }

        a {
            @include grid-area(1, 2, 4, 5);
        }

        &:nth-of-type(odd) {
            @include mq ($from: $viewport--md) {
                @include grid (40% 1fr, 140px auto 50px);

                &::before {
                    @include grid-area(2, 3, 1, 3);
                    margin-left: -75px;
                    margin-right: calc((100vw - 1140px) / -2);

                    @include mq ($until: $viewport--xl - 50px) {
                        margin-right: -40px;
                    }
                }

                picture {
                    @include grid-area(1, 2, 2, 4);
                    margin-left: calc((100vw - 1140px) / -2);
                    width: calc(100% + (100vw - 1140px) / 2);

                    @include mq ($until: $viewport--xl - 50px) {
                        margin-left: -40px;
                        width: calc(100% + 40px);
                    }


                }

                h2 {
                    @include grid-area(2, 3, 1, 2);
                    display: flex;
                }

                .text {
                    @include grid-area(2, 3, 2, 3);
                }

                a {
                    @include grid-area(2, 3, 3, 4);
                }

            }
        }

        &:nth-of-type(even) {
            @include mq ($from: $viewport--md) {
                @include grid (1fr 40%, 140px auto auto 50px);

                &::before {
                    @include grid-area(1, 3, 2, 5);
                    // margin-left: -75px;
                    margin-left: calc((100vw - 1140px) / -2);

                    @include mq ($until: $viewport--xl - 50px) {
                        margin-left: -40px;
                    }
                }

                picture {
                    @include grid-area(2, 3, 1, 4);
                    //margin-left: calc((100vw - 1140px) / -2);
                    //width: calc(100% + (100vw - 1140px) / 2);

                    // @include mq ($until: $viewport--xl - 50px) {
                    //     margin-left: -40px;
                    //     width: calc(100% + 40px);
                    // }
                    img {
                        max-height: 650px;
                    }
                }

                h2 {
                    @include grid-area(1, 2, 2, 3);
                    display: flex;
                }

                .text {



                    @include grid-area(1, 2, 3, 4);
                }

                a {
                    @include grid-area(1, 3, 4, 5);
                }

            }
        }

        display: grid;
        background-color: $cc-grey;

        h2 {

            align-items: center;
            margin-bottom: 0;
            padding: 50px;
        }

        .text {
            span {
                padding: 0 25px;

                @include mq ($until: 850px, $from: $viewport--sm) {

                    width: 100% !important;

                }
            }

        }

        @include mq ($from: $viewport--md) {
            background-color: #ffffff;


            &::before {
                content: '';
                background-color: $cc-grey;
                display: block;
            }

            picture {
                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }

    }
}

.ContactPage {
    h1 {
        color: #ffffff;
        @include font-responsive($viewport--xs, $viewport--md, 35, 80);
        font-weight: 100;
    }

    .inner {
        h2 {
            margin-bottom: 8px;
            font-size: 1.4rem;
            font-weight: 100;
        }
    }

    .Form {
        .Form-item {
            margin-left: 10%;
            position: relative;

            label {
                color: #ffffff;
                position: relative;
            }

            input {
                max-width: 100%;
                padding: 8px;
            }
        }
    }
}
