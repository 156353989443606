/**
 * Main
 */

html {
    background-color: $background-color;
    color: $text-color;
    font-family: $larsseit;
    height: 100%;
}

body {
    min-height: 100%;
    overflow-x: hidden;
}
