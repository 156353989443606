/**
 * Settings: Fonts
 */

// Default
$font-sans:                             "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-serif:                            Georgia, "Times New Roman", Times, serif !default;
$font-code:                             Menlo, Monaco, Consolas, monospace, serif !default;

// Custom
$font-custom-font:                      "custom-font", sans-serif !default; // 400, 700
$larsseit:                              "Larsseit", sans-serif !default;
