.kern {
    margin: 25px auto;

    h3 {
        color: $cc-orange;

    }

    .kernInner {
        padding: 18px;

        .colorBar {
            width: 100%;
            height: 16px;
            background-color: $cc-orange;
            margin-bottom: 16px;
        }
    }

}

.categorie-block {

    .categorie-head {
        color: #ffffff;

        h1 {
            font-size: 3.5rem;

        }
    }
}

.categorie-body {
    .Button--primary {
        width: 100%;
        padding: 12px 25px;
        text-transform: uppercase;
    }


    .categorie-dates {



        .inner {
            background-color: #ffffff;
            padding: 50px 50px;
            height: 100%;
            margin: 0 -15px;

            p {
                margin-bottom: 0px;
            }

            .alert {
                color: $cc-orange;
                border: 2px solid $cc-orange;
                padding: 25px;
                font-weight: 500;
            }

            .quote {
                h2 {
                    color: $cc-orange;
                    font-weight: 500;
                    font-size: 1.8rem;
                }
            }

            .grid-inner--gutter {
                width: 5%;
            }

            .grid-inner {
                background-color: #ffffff;

                @include mq ($until: $viewport--sm) {
                    padding: 50px 15px;
                }

                padding: 50px 25px;
                width: 45%;
                margin-bottom: 25px;

                @include mq ($until: $viewport--md + 200px) {
                    width: 100%;
                }



                border: solid 1px $cc-orange;

                .title {
                    color: grey;
                    text-decoration: underline;
                }

                h2 {
                    color: $cc-orange;
                    font-size: 2rem;
                }
            }
        }

        position: relative;

        &::before {
            content: "";
            top: 50px;
            bottom: 0;
            left: 0;
            width: 2px;
            background-color: $cc-orange;
            position: absolute;
        }

        .sessie {
            position: relative;

            a {
                @include mq($until: $viewport--md) {
                    text-decoration: none !important;
                    margin-top: 8px;
                }
            }

            p {
                margin-bottom: 0px;
                font-weight: 500;
            }

            &::after {
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                width: 79%;
                border-bottom: 1px solid #1a1a18;

                @include mq($until: $viewport--md) {
                    bottom: -2px;
                }
            }

            .Grid-cell {
                &:nth-child(1) {
                    @include mq($until: $viewport--md) {
                        margin-bottom: 20px !important;
                    }
                }

                &:nth-child(2) {
                    position: absolute;
                    right: 0;
                    bottom: -6px;

                    a {
                        color: $cc-black;
                    }
                }
            }


        }
    }


}




.categorie-aanbod {
    margin-top: -75px;
    padding-left: 25px;

    @include mq ($until: $viewport--sm) {
        margin-top: 25px;
        padding-left: 0px;
    }

    .categorie-aanbod-block {
        background-color: $cc-grey;
        color: $cc-black;
        padding: 50px 25px;

        &:hover {
            background-color: $cc-black;
            color: #ffffff;

            a {
                color: #ffffff;
                transition: .7s all ease;
            }

            transition: .7s all ease;
        }

        p {
            color: $cc-orange;
            font-size: 1.3rem;
            margin-bottom: 8px;
            font-weight: 500;
        }

        a {
            color: $cc-black;
            font-weight: 500;
        }

        &.active {
            background-color: $cc-black;
            color: #ffffff;

            a {
                visibility: hidden;
            }
        }
    }

}

.grid-inner {}
