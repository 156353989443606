/**
 * Button
 */

// Primary
.Button--primary {
    background-color: $cc-black;
    color: #ffffff;
    text-decoration: none;
    padding: 10px 25px;
    display: inline-block;
    transition: opacity .6s;
    font-weight: 500;

    &:hover,
    &.is-hovered,
    &:focus,
    &.is-focused,
    &:active,
    &.is-active {
        text-decoration: none;
        color: #ffffff;
        opacity: .6;
    }
}

// Secondary
.Button--secondary {
    color: #ffffff;
    background-image: url('/assets/img/bg.jpg');
    background-size: cover;
    height: 100%;
    width: 100%;
    min-height: 40px;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: 1.3rem;

    &:before {
        content: "";
        background-color: #ffffff;
        width: 13%;
        height: 2px;
        margin-left: 15px;
        margin-right: 10px;
    }

    &:hover,
    &.is-hovered,
    &:focus,
    &.is-focused,
    &:active,
    &.is-active {
        color: $cc-orange;
        text-decoration: underline;

        &::before {
            background-color: $cc-orange;
        }
    }
}

// Success
.Button--success {
    background-color: $green;
    border-color: $green;
    color: $white;

    &:hover,
    &.is-hovered,
    &:focus,
    &.is-focused,
    &:active,
    &.is-active {
        background-color: $green--700;
        border-color: $green--700;
        color: $white;
    }
}

// Info
.Button--info {
    color: $cc-orange;
    text-decoration: none;
    text-align: center;
    width: 100%;
    font-weight: 500;

    border-top: 2px solid $cc-orange;
    border-bottom: 2px solid $cc-orange;
    padding: 12px 0;
    
    transition: background-color .8s, color .8s;

    &:hover,
    &.is-hovered,
    &:focus,
    &.is-focused,
    &:active,
    &.is-active {
        background-color: $cc-orange;
        color: $white;
    }
}

// Warning
.Button--warning {
    background-color: $orange;
    border-color: $orange;
    color: $white;

    &:hover,
    &.is-hovered,
    &:focus,
    &.is-focused,
    &:active,
    &.is-active {
        background-color: $orange--700;
        border-color: $orange--700;
        color: $white;
    }
}

// Danger
.Button--danger {
    background-color: $red;
    border-color: $red;
    color: $white;

    &:hover,
    &.is-hovered,
    &:focus,
    &.is-focused,
    &:active,
    &.is-active {
        background-color: $red--700;
        border-color: $red--700;
        color: $white;
    }
}

// Large
.Button--large {
    @include font-size($button-font-size * 1.25, false);
    padding-right: u($spacing-unit--lg);
    padding-left: u($spacing-unit--lg);
}

// Small
.Button--small {
    @include font-size($button-font-size * .75, false);
    padding-right: u($spacing-unit--sm);
    padding-left: u($spacing-unit--sm);
}

// Full
.Button--full {
    width: 100%;
}
