/**
 * Nav
 */

// Main

.Nav--main {

    .Nav-listToggle {
        width: 25px;
        path {
            color: #ffffff;
        }
    }

    .Nav-list {
        @include mq ($until: $viewport--md){
            position: absolute;
            right: 25px;
            z-index: 99;
            display: block;
            background-color: $cc-orange;
            opacity: 1;

            .Nav-item {
                width: 100%;
                padding: 12px 50px;
                .Nav-link {
                    text-align: center;
                }
            }
        }

    }

    .Nav-item {
        padding-left: 65px;
        font-weight: 800;
    }

    .Nav-link {
        color: #ffffff;
        border-bottom: 6px solid transparent;
        transition: .6s all ease;

        &:hover {
            border-bottom: 6px solid #ffffff;
        }
    }

    .is-active {
        border-bottom: 6px solid #ffffff;
    }

}

// Languages

.Nav--lang {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    margin: 25px 0;

    .Nav-listToggle {}

    .Nav-list {}

    .Nav-item {
        margin-left: 20px;
        font-weight: 800;
    }

    .Nav-link {
        color: #ffffff;

        &:hover {
            color: $cc-orange;
        }
    }

    .is-active {
        color: $cc-orange;
    }
}
