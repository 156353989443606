/**
 * Logo
 */

.Logo {
    svg {
        width: 175px;
        height: 45px;
    }
}

.Nav {
 .logo {
     margin-right: auto;
 }
}
