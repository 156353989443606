/**
 * Header
 */

.Header {
    z-index: 99;
    height: 150px;
}

.bg-image {
    background-image: url('/assets/img/bg.jpg');
    background-size: cover;
    height: 100%;
    width: 100%;
    @include grid-area(1, 2, 1, 3);
    margin-top: -150px;
    height: calc(100% + 150px);
}


